import { FC, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'

import { Card, Kpi, Spinner, ErrorBanner } from 'ds/components'

import { formatNumber } from 'lib/utils/formatNumber'
import { castDecimal } from 'lib/utils/castDecimal'
import { formatCurrency } from 'lib/utils/formatCurrency'
import { getErrorFromQuery } from 'lib/utils/getErrorFromQuery'
import { dashboardService } from 'lib/services/dashboardService'

type Props = {
    currency: string
    organizationId: number | null
}

export const ReceivablesAgingKpis: FC<Props> = ({ currency, organizationId }) => {
    const queryParams = useMemo(
        () => ({ currency, organization_id: organizationId }),
        [currency, organizationId]
    )

    const { isLoading, data, error } = useQuery({
        queryKey: ['dashboard', 'receivables-aging', queryParams],
        queryFn: () => dashboardService.getReceivablesAging(queryParams),
    })

    const errorMessage = getErrorFromQuery(error, data)

    const percentOutstanding = useMemo(() => {
        if (!data) return 0
        return castDecimal(data.receivablesAging.total_overdue)
            .dividedBy(castDecimal(data.receivablesAging.total_outstanding))
            .times(100)
            .toNumber()
    }, [data])

    if (isLoading) return <Spinner />
    if (errorMessage || !data) return <ErrorBanner>{errorMessage}</ErrorBanner>

    const { receivablesAging } = data

    return (
        <Card>
            <div className="row center">
                <div className="col-lg-3 col-md-6">
                    <Kpi label="Por Cobrar">
                        {getAmountDisplay(receivablesAging.total_outstanding)}
                    </Kpi>
                </div>

                <div className="col-lg-3 col-md-6">
                    <Kpi label="Vigente">
                        {getAmountDisplay(receivablesAging.within_due)}
                    </Kpi>
                </div>

                <div className="col-lg-3 col-md-6">
                    <Kpi label="Vencido">
                        {getAmountDisplay(receivablesAging.total_overdue)}
                    </Kpi>
                </div>

                <div className="col-lg-3 col-md-6">
                    <Kpi label="Porcentaje Vencido">
                        {formatNumber(percentOutstanding, 0)}%
                    </Kpi>
                </div>
            </div>
        </Card>
    )
}

const getAmountDisplay = (amount: number | null) => {
    if (!amount) return '$0'
    return `${formatCurrency(amount, 0)}`
}
