import { FC, useMemo } from 'react'
import { orderBy } from 'lodash'
import { useQuery } from '@tanstack/react-query'

import { KeyValue, Spinner, ErrorBanner, Card } from 'ds/components'

import { reportService } from 'lib/services/reportService'
import { castDecimal } from 'lib/utils/castDecimal'
import { formatCurrency } from 'lib/utils/formatCurrency'
import { getErrorFromQuery } from 'lib/utils/getErrorFromQuery'

type Props = {
    organization_id: number | null
    dateParam: string
    currency: string | undefined
    start: Date | null
    end: Date | null
}

export const AmountDueByCustomer: FC<Props> = ({
    organization_id,
    dateParam,
    currency,
    start,
    end,
}) => {
    const queryParams = useMemo(
        () => ({ organization_id, dateParam, currency, start, end }),
        [organization_id, dateParam, currency, start, end]
    )

    const { isLoading, error, data } = useQuery({
        queryKey: ['receivables-aging-by-customer', queryParams],
        queryFn: () => reportService.getReceivablesAgingByCustomer(queryParams),
    })

    const errorMessage = getErrorFromQuery(error, data)

    const { rows, total } = useMemo(() => {
        const receivablesAgingByCustomer = data?.receivablesAgingByCustomer || []

        const rows = orderBy(
            receivablesAgingByCustomer,
            (r) => Number(r.total_outstanding),
            'desc'
        )

        const total = rows.reduce(
            (acc, row) => acc.plus(row.total_outstanding),
            castDecimal(0)
        )

        return { rows, total: total.toNumber() }
    }, [data])

    if (isLoading) return <Spinner />
    if (errorMessage)
        return <ErrorBanner>Ha ocurrido un error: {errorMessage}</ErrorBanner>

    return (
        <Card>
            <h3>Cobranza por Cliente</h3>
            <div style={{ height: '368px', overflow: 'scroll' }}>
                {rows.map((row) => (
                    <div key={row.customer_id}>
                        <KeyValue title={row.customer} right>
                            {formatCurrency(row.total_outstanding, 0)}
                        </KeyValue>
                    </div>
                ))}
                <KeyValue title="Total" right>
                    <strong>{formatCurrency(total, 0)}</strong>
                </KeyValue>
            </div>
        </Card>
    )
}
