import { FC, ReactNode, useEffect, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'

import { SelectInput } from 'ds/components/SelectInput'

import { organizationService } from 'lib/services/organizationService'
import { Organization } from 'lib/models/organization'

type Props = {
    className?: string
    useStrongLabel?: boolean
    label?: string
    organizationId: number | null
    error?: string
    suggestedAction?: ReactNode
    help?: string
    placeholder?: string

    required?: boolean
    disabled?: boolean
    showAll?: boolean // Show organizations no matter length and not "Todas mis empresas"
    selectIfOne?: boolean // Select the only organization if there is only one

    onChange: (organization_id: number | null, organization?: Organization) => void
}

export const OrganizationSelect: FC<Props> = ({
    className,
    useStrongLabel,
    organizationId,
    onChange,
    label,
    showAll,
    required,
    error,
    suggestedAction,
    disabled,
    selectIfOne,
    help,
    placeholder,
}) => {
    const { data } = useQuery({
        queryKey: ['organization'],
        queryFn: () => organizationService.getOrganizations(),
    })

    const orgs = data?.organizations

    useEffect(() => {
        if (!selectIfOne || !orgs || organizationId || orgs.length !== 1) return

        onChange(orgs[0].organization_id, orgs[0])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectIfOne, organizationId, orgs])

    const items = useMemo(() => {
        if (!orgs?.length) return []
        if (orgs.length === 1 && !showAll) return []

        const orgsToDisplay = showAll
            ? orgs
            : [{ organization_id: null, legal_name: 'Todas mis empresas' }, ...orgs]

        return orgsToDisplay.map((o) => {
            const displayName = o.display_name || o.legal_name
            return {
                organization_id: o.organization_id,
                legal_name: o.legal_name,
                display_name:
                    showAll && o.tax_id ? `${displayName} (${o.tax_id})` : displayName,
            }
        })
    }, [orgs, showAll])

    const value = useMemo(() => {
        const orgId = organizationId || null
        return items.find((o) => o.organization_id === orgId)
    }, [items, organizationId])

    if (!items.length && !showAll) return null

    return (
        <SelectInput
            className={className}
            useStrongLabel={useStrongLabel}
            disabled={disabled}
            help={help}
            required={required}
            error={error}
            label={label}
            placeholder={placeholder || (label ? undefined : 'Empresa')}
            value={value}
            onChange={(o) => {
                const org = o?.organization_id
                    ? orgs?.find((org) => org.organization_id === o.organization_id)
                    : null
                if (org) onChange(org.organization_id, org)
                else onChange(null)
            }}
            items={items}
            getItemId={(o) => o.organization_id}
            itemToString={(o) => o.display_name}
            suggestedAction={suggestedAction}
        />
    )
}
