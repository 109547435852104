import { FC, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'

import { Card, Spinner, ErrorBanner, Button } from 'ds/components'
import { BarChart } from 'lib/common/BarChart'

import { colors } from 'lib/utils/colors'
import { getErrorFromQuery } from 'lib/utils/getErrorFromQuery'
import { dashboardService } from 'lib/services/dashboardService'

type Props = {
    currency: string
    organizationId: number | null
}

export const ReceivablesAgingChart: FC<Props> = ({ currency, organizationId }) => {
    const queryParams = useMemo(
        () => ({ currency, organization_id: organizationId }),
        [currency, organizationId]
    )
    const { isLoading, data, error } = useQuery({
        queryKey: ['dashboard', 'receivables-aging', queryParams],
        queryFn: () => dashboardService.getReceivablesAging(queryParams),
    })

    const errorMessage = getErrorFromQuery(error, data)

    const chartData: { name: string; value: number }[] = useMemo(() => {
        if (!data) return []
        const { receivablesAging } = data
        return [
            { name: 'Vigente', value: Number(receivablesAging.within_due) },
            { name: '1-30 Días', value: Number(receivablesAging['1to30d']) },
            { name: '31-60 Días', value: Number(receivablesAging['31to60d']) },
            { name: '61-90 Días', value: Number(receivablesAging['61to90d']) },
            { name: '90+ Días', value: Number(receivablesAging.gt90d) },
        ]
    }, [data])

    if (isLoading) return <Spinner />
    if (errorMessage) return <ErrorBanner>{errorMessage}</ErrorBanner>

    return (
        <Card>
            <div className="d-flex align-items-center justify-content-between">
                <h3>Cartera Vencida</h3>
                <Button href="/reports?type=ReceivablesAgingReport" variant="primaryText">
                    Reporte →
                </Button>
            </div>
            <BarChart data={chartData} color={colors.red} />
        </Card>
    )
}
