import { downloadFile, makeApiRequest } from 'lib/utils/apiUtils/makeApiRequest'
import { formatDateTimestamp } from 'lib/utils/formatDateTimestamp'

const url = '/reports'

export const reportService = {
    getPaymentTotals: async (queryParams) =>
        makeApiRequest({ url: `${url}/payment-totals`, queryParams }),
    getInvoiceTotals: async (queryParams) =>
        makeApiRequest({ url: `${url}/invoice-totals`, queryParams }),
    getInvoiceTotalsByMonth: async (queryParams) =>
        makeApiRequest({ url: `${url}/invoice-totals-by-month`, queryParams }),
    getRevenueByMonth: async (queryParams) =>
        makeApiRequest({ url: `${url}/revenue-by-month`, queryParams }),
    getCustomerReport: async (body) =>
        makeApiRequest({ url: `${url}/customer`, method: 'POST', body }),
    getProductReport: async (body) =>
        makeApiRequest({ url: `${url}/product`, method: 'POST', body }),
    getSalesByItemReport: async (queryParams) => {
        if (queryParams.download) {
            const filename = `ventas-por-producto-${formatDateTimestamp(new Date())}.csv`
            return downloadFile({ url: `${url}/sales-by-item`, queryParams, filename })
        }
        return makeApiRequest({
            url: `${url}/sales-by-item`,
            method: 'GET',
            queryParams,
        })
    },
    getBankTransactionReport: async (queryParams) =>
        makeApiRequest({ url: `${url}/bank-transaction`, queryParams }),
    getDaysSalesOutstandingReport: async (queryParams) =>
        makeApiRequest({ url: `${url}/days-sales-outstanding`, queryParams }),
    getReceivablesAgingByCustomer: async (queryParams) =>
        makeApiRequest<GetReceivablesAgingByCustomerData>({
            url: `${url}/receivables-aging/customer`,
            queryParams,
        }),
    downloadReceivablesAgingByCustomer: async (queryParams) => {
        const filename = `cartera-vencida-por-cliente-${formatDateTimestamp(
            new Date()
        )}.csv`
        return downloadFile({
            url: `${url}/receivables-aging/customer`,
            queryParams: { ...queryParams, download: true },
            filename,
        })
    },
    getReceivablesByCustomerAndProduct: async (queryParams) =>
        makeApiRequest({
            url: `${url}/receivables-by-customer-product`,
            queryParams,
        }),
    getReceivablesByProduct: async (queryParams) =>
        makeApiRequest({ url: `${url}/receivables-by-product`, queryParams }),
    getOutstandingReport: async (queryParams) =>
        makeApiRequest({
            url: `${url}/invoice-outstanding-by-date`,
            queryParams,
        }),
    getEmailEventsByCustomer: async (queryParams) =>
        makeApiRequest({
            url: `${url}/email-events-by-customer`,
            queryParams,
        }),

    // Superadmin reports
    getAccountStats: async () => makeApiRequest({ url: `${url}/account-stats` }),
    getActiveStats: async () => makeApiRequest({ url: `${url}/active-stats` }),
    getRevenueStats: async () => makeApiRequest({ url: `${url}/revenue-stats` }),
    getPaidPaymentOrders: async (queryParams) =>
        makeApiRequest({ url: `${url}/paid-payment-orders`, queryParams }),
}

type GetReceivablesAgingByCustomerData = {
    receivablesAgingByCustomer: {
        customer_id: number
        customer: string
        within_due: number
        overdue_bucket_1: number
        overdue_bucket_2: number
        overdue_bucket_3: number
        overdue_bucket_4: number
        total_overdue: number
        total_outstanding: number
    }[]
    days: number
}
