import { FC } from 'react'
import { useRouter } from 'next/router'
import * as icons from 'react-bootstrap-icons'

import { Card, Icon, Modal } from 'ds/components'

type Props = {
    onClose: () => void
}

const pages = {
    subscription: `/contracts/subscriptions/create`,
    payment_plan: `/contracts/payment-plans/create`,
    credit: `/contracts/credits/create`,
}

export const CreateContractModal = ({ onClose }: Props) => {
    const router = useRouter()

    const handleClick = (path: string) => {
        router.push(path)
        onClose()
    }

    return (
        <Modal title="Selecciona el tipo de contrato" size="md" onClose={onClose}>
            <div className="d-flex flex-column gap-2">
                <ContractOption
                    title="Recurrente"
                    description="Crea cobros con una frecuencia definidia."
                    icon="Repeat"
                    onClick={() => handleClick(pages.subscription)}
                />
                <ContractOption
                    title="Plan de pago"
                    description="Crea un plan de pago con fechas y montos específicos."
                    icon="CardList"
                    onClick={() => handleClick(pages.payment_plan)}
                />
                <ContractOption
                    title="Crédito"
                    description="Configura un crédito con términos y condiciones personalizadas."
                    icon="Bank"
                    onClick={() => handleClick(pages.subscription)}
                />
            </div>
        </Modal>
    )
}

type ContractOptionProps = {
    title: string
    description: string
    icon: keyof typeof icons
    onClick: () => void
}

const ContractOption: FC<ContractOptionProps> = ({
    title,
    description,
    icon,
    onClick,
}) => {
    return (
        <Card className="hover-pointer border" variant="border" onClick={onClick}>
            <div className="d-flex gap-3 align-items-center">
                <Icon size={22} icon={icon} />
                <div className="d-flex flex-column gap-1">
                    <h5 className="mb-0">{title}</h5>
                    <span className="mb-0 neutral-800 fs-7">{description}</span>
                </div>
            </div>
        </Card>
    )
}
