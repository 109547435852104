import { makeApiRequest } from 'lib/utils/apiUtils/makeApiRequest'

const url = '/dashboard'

export const dashboardService = {
    getReceivablesKpis: async (queryParams: Filter) =>
        makeApiRequest({
            url: `${url}/receivables-kpis`,
            method: 'GET',
            queryParams,
        }),
    getReceivablesAging: async (queryParams: Filter) =>
        makeApiRequest({ url: `${url}/receivables-aging`, method: 'GET', queryParams }),
    getDaysSalesOutstanding: async (queryParams: Filter) =>
        makeApiRequest({
            url: `${url}/days-sales-outstanding`,
            method: 'GET',
            queryParams,
        }),
    getRecoveryOverdueInvoices: async (queryParams: Filter) =>
        makeApiRequest({
            url: `${url}/recovery-overdue-invoices`,
            method: 'GET',
            queryParams,
        }),
    getTopOverdueCustomersWithCommunicationProblems: async (queryParams: Filter) =>
        makeApiRequest({
            url: `${url}/top-overdue-customers-communication-problems`,
            method: 'GET',
            queryParams,
        }),
    getTopOverdueCustomers: async (queryParams: Filter) =>
        makeApiRequest({
            url: `${url}/top-overdue-customers`,
            method: 'GET',
            queryParams,
        }),
    getInvoiceAmountsByMonth: async (queryParams: Filter) =>
        makeApiRequest({
            url: `${url}/invoice-amounts-by-month`,
            method: 'GET',
            queryParams,
        }),
    getRevenueByMonth: async (queryParams: Filter) =>
        makeApiRequest({
            url: `${url}/revenue-by-month`,
            method: 'GET',
            queryParams,
        }),
    getTotalEmailEventsLast30Days: async (queryParams: Filter) =>
        makeApiRequest({
            url: `${url}/total-email-events`,
            method: 'GET',
            queryParams,
        }),
}

type Filter = {
    currency: string
    organization_id: number | null
    start?: Date | undefined | null
    end?: Date | undefined | null
    has_cfdi?: string | null
    date_param?: string
}
